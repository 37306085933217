@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
  scroll-behavior: smooth;
} */

body {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #1c1c1c;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bolder;
}

/* body::-webkit-scrollbar {
  width: 0.8rem;
} */

body::-webkit-scrollbar-track {
  border-radius: 0;
}

/* body::-webkit-scrollbar-thumb {
  background-color: #1d1d1e;
  border-radius: 0;
} */

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(31, 30, 72, 0.5);
  font-style: italic;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(31, 30, 72, 0.5);
  font-style: italic;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(31, 30, 72, 0.5);
  font-style: italic;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(31, 30, 72, 0.5);
  font-style: italic;
}

input::placeholder {
  /* Firefox 18- */
  color: rgba(31, 30, 72, 0.5);
  font-style: italic;
}

table {
  border-collapse: separate;
  border-spacing: 0 12px;
}

img {
  max-width: 100%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-image: url("assets/images/arrow-angle.svg");
  background-repeat: no-repeat;
  background-position: 96% 12px;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
  appearance: none;
  border: 2px solid #c9c9c9;
  font: inherit;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
}

input[type="checkbox"]::before {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fe8664;
  background-image: url(assets/images/checkbox-mark.svg);
  background-size: contain;
  border-radius: 0.25rem;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

[type="password"] {
  /* background-image: url(assets/images/off.svg);
  background-repeat: no-repeat;
  background-position: 96% center; */
}

ol li {
  position: relative;
  padding-left: 12px;
}

ol li::before {
  content: url(assets/images/disc.svg);
  position: absolute;
  left: 0;
}

/*
=====================
CSS Custom 
=====================
*/
/* .dashboard-sidebar {
  width: 270px;
  height: 100vh;

  overflow-y: scroll;
  transition: left ease 300ms;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.dashboard-sidebar::-webkit-scrollbar {
  display: none;
}
.dashboard-main {
  width: 100%;
  overflow: none;
}

.table-wrapper::-webkit-scrollbar {
  width: 0.2rem;
}

.table-wrapper::-webkit-scrollbar-track {
  border-radius: 0;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #959595;
  border-radius: 10px;
}

.table-wrapper {
  padding-right: 12px;
}

.table-wrapper table {
  min-width: 820px;
}

.table-collapse .open {
  display: none !important;
}

.table-collapse .close {
  display: block !important;
}

.table-collapse .table-collapse-body {
  display: block !important;
}

/*
=====================
CSS Responsive 
=====================
*/

@media only screen and (max-width: 640px) {
  /* .dashboard-sidebar {
    left: -200px;
  } */

  .sidebar-open {
    left: 0 !important;
  }
}
